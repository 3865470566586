<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <form class="mb-9" v-on:submit.prevent="updateItem" v-if="!preloader">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Профиль</h2>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-xl-8">
                            <h4 class="fs-1 mb-4">Основная информация</h4>
                            <div class="row g-3">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            disabled
                                            v-model="user.email"
                                            @input="errors.user.email = null"
                                            :class="{'is-invalid': errors.user.email}"
                                            class="form-control"
                                            id="email" 
                                            type="text" 
                                            placeholder="Email"/>
                                        <label for="email">Email</label>
                                        <div class="invalid-feedback">{{errors.user.email}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.personal.country}">
                                        <vue-select
                                            v-model="user.personal.country"
                                            @input="errors.user.personal.country = null"
                                            :options="utils.countries"
                                            placeholder="Страна"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Страна</label>
                                        <div class="invalid-feedback">{{errors.user.personal.country}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.telegram"
                                            @input="errors.user.personal.telegram = null"
                                            :class="{'is-invalid': errors.user.personal.telegram}"
                                            class="form-control" 
                                            id="telegram" 
                                            type="text" 
                                            placeholder="Телеграм ID"/>
                                        <label for="telegram">Телеграм ID</label>
                                        <div class="invalid-feedback">{{errors.user.personal.telegram}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            disabled
                                            v-model="user.percent"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Процент"/>
                                        <label>Процент</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            disabled
                                            v-model="user.ref_id"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Реферер"/>
                                        <label>Реферер</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            disabled
                                            v-model="user.ref_percent"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Процент с реферала"/>
                                        <label>Процент с реферала</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-12">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.wallet"
                                            @input="errors.user.personal.wallet = null"
                                            :class="{'is-invalid': errors.user.personal.wallet}"
                                            class="form-control" 
                                            id="wallet" 
                                            type="text" 
                                            placeholder="Платежные реквизиты"/>
                                        <label for="wallet">Платежные реквизиты</label>
                                        <div class="invalid-feedback">{{errors.user.personal.wallet}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-12 mb-3">
                                    <div class="form-floating partner-link-raw">
                                        <input
                                            disabled
                                            v-model="ref_link"
                                            placeholder="Реферальная ссылка" 
                                            type="text" 
                                            class="form-control partner-link-input" />
                                        <label>Реферальная ссылка</label>
                                        <button
                                            @click="copyLink"
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            content="Скопировать ссылку"
                                            type="button"
                                            class="btn btn-soft-default tournament-button-right">
                                            <span class="vertical-center-item"><i class="fa-regular fa-copy"></i></span>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-4">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Сохранить изменения</span>
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div class="col-xl-4">

                            <h4 class="fs-1 mb-4">Сменить пароль</h4>

                            <form class="mb-9" v-on:submit.prevent="changePassword">
                                <div class="row g-3 mb-4">
                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input
                                                v-model="password.password"
                                                @input="errors.password.password = null"
                                                :class="{'is-invalid': errors.password.password}"
                                                class="form-control" 
                                                id="password" 
                                                type="password" 
                                                placeholder="Новый пароль"/>
                                            <label for="password">Новый пароль</label>
                                            <div class="invalid-feedback">{{errors.password.password}}</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input 
                                                v-model="password.password_confirmation"
                                                @input="errors.password.password_confirmation = null"
                                                :class="{'is-invalid': errors.password.password_confirmation}"
                                                class="form-control" 
                                                id="password_confirmation" 
                                                type="password" 
                                                placeholder="Подтвердите новый пароль"/>
                                            <label for="password_confirmation">Подтвердите новый пароль</label>
                                            <div class="invalid-feedback">{{errors.password.password_confirmation}}</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12">
                                        <div class="d-flex">
                                            <button
                                                :class="{'disabled': btn_preloader}"
                                                class="btn btn-primary px-5 w-100 text-nowrap" 
                                                type="submit">
                                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                                <span>Сменить пароль</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            ref_link: null,
            user: null,
            password: {
                password: null,
                password_confirmation: null
            },
            utils: {
                countries: []
            },
            errors: {
                user: {
                    email: false,
                    personal: {
                        telegram: false,
                        phone: false,
                        country: false,
                        wallet: false
                    }
                },
                password: {
                    password: false,
                    password_confirmation: false
                }
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: false,
                closeonesc: true,
                autoapply: true,
                single: true,
                ranges: false,
                dropdowns: true,
                maxdate: this.$moment().set({year: this.$moment().get('year') - 18}).format('Y-MM-DD'),
                opens: 'right'
            }
        }
    },
    methods: {
        async getItem () {
            await this.$axios.post('/users/item')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.user = response.data

                        this.user.personal.country = this.utils.countries.find(row => row.key == this.user.personal.country)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        updateItem () {
            this.btn_preloader = true

            this.$axios.post('/users/update', JSON.stringify({
                personal: {
                    telegram: this.user.personal.telegram,
                    phone: this.user.personal.phone,
                    country: this.user.personal.country ? this.user.personal.country.key : null,
                    wallet: this.user.personal.wallet                    
                }
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors.user, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        changePassword () {
            if(!this.password.password) {
                this.errors.password.password = 'Это поле обязательно'
                return
            }
            if(!this.password.password_confirmation) {
                this.errors.password.password_confirmation = 'Это поле обязательно'
                return
            }

            this.btn_preloader = true
            this.$axios.post('/users/changepassword', JSON.stringify(this.password))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.password = {
                            password: null,
                            password_confirmation: null
                        }
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors.password[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        async getCountries () {
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })    
        },
        copyLink () {
            navigator.clipboard.writeText(this.ref_link)
            this.$toast.success('Скопировано')
        }
    },
    async beforeMount() {
        await this.getCountries()
        await this.getItem()

        this.ref_link = `https://${this.$store.state.user.data.link}/redirect?ref_id=${this.$store.state.user.data.id}&subid={SUBID}`
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
