<template>
	<nav class="navbar navbar-top navbar-expand">
	    <div class="navbar-logo">
	        <button type="button" class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent">
	            <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
	        </button>
	        <router-link class="navbar-brand me-1 me-sm-3" :to="{name: 'Home'}">
	            <div class="d-flex align-items-center">
	                <div class="d-flex align-items-center">
	                    <p class="logo-text ms-2 d-none d-sm-block">{{$config.ApplicationNAME}}</p>
	                </div>
	            </div>
	        </router-link>
	    </div>

	    <ul class="navbar-nav navbar-nav-icons flex-row flex-menu-right">
	    	<!--<li class="nav-item" style="margin-right: 20px;">
                {{ parseFloat($store.state.user.data.balance).toFixed(2) }} RUB
	        </li>-->

	        <li class="nav-item dropdown">
                <div class="sun-change-theme">
	                <i class="fa-regular fa-sun-bright"></i>
	                <i class="fa-regular fa-moon"></i>
	            </div>
	        </li>
	    </ul>
	</nav>
</template>

<script>
export default {
    mounted () {
    	var th = this
    	document.querySelector('.sun-change-theme').addEventListener('click', function(e){
    		document.documentElement.classList.toggle('dark')

    		let theme = localStorage.getItem('theme')
    		localStorage.setItem('theme', parseInt(theme) ? 0 : 1)

    		th.setTheme(parseInt(theme) ? 'white' : 'dark')
    	})

    	document.querySelector('.navbar-toggler').addEventListener('click', function(e){
    		document.getElementById('navbarVerticalCollapse').classList.toggle('show')
    	})
    },
    beforeMount () {
    	let theme = localStorage.getItem('theme')
    	if (parseInt(theme)) {
    		this.setTheme('dark')
    	} else {
    		this.setTheme('white')
    	}
    }
}
</script>