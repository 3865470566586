import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './events/Store'

import Http from './events/Http'
import Config from './events/Config'

import Common from './common'

import Login from './components/Login'
import Register from './components/Register'

/** Profile **/
import Profile from './components/Profile'

/** Statistics **/
import StatisticsItems from './components/statistics/Items'
import StatisticsReferrals from './components/statistics/Referrals'

/** Payments **/
import PaymentsItems from './components/payments/Items'



Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: '',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/statistics',
            name: 'StatisticsItems',
            component: StatisticsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/statistics/referrals',
            name: 'StatisticsReferrals',
            component: StatisticsReferrals,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/payments',
            name: 'PaymentsItems',
            component: PaymentsItems,
            meta: {
                auth: 'auth'
            }
        },
    ]
})

router.beforeEach(async (to, from, next) => {

    var user = Store.state.user

    if(!('auth' in user)) {
        await Http.post(`${Config.ApplicationAPI}/auth/check`)
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    console.log('check auth error')
                    break
                case 'success':
                    document.body.classList.remove('overflow-hidden')
                    Store.commit('setLoaded', true)
                    Store.commit('setUser', response.user)
                    break
                default:
                    console.log('Unknown error')
            }
        }).catch((error) => {
            document.body.classList.remove('overflow-hidden')
            Store.commit('setLoaded', true)
        })

        user = Store.state.user
    }

    if((!('auth' in user) || !user.auth) && to.meta.auth == 'auth' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Login'})
    } else if((('auth' in user) && user.auth) && to.meta.auth == 'guest' && from.meta.auth != to.meta.auth) {
        router.push({name: 'StatisticsItems'})
    }

    next()

})


export default router