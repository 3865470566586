<template>
	<main class="main">
	    <div class="container-fluid px-0">
	        <div class="container">
	            <div class="row flex-center min-vh-100 py-5">
	                <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
	                	<form v-on:submit.prevent="submitForm">
		                    <div class="text-center mb-7">
		                        <h3 class="text-1000">Регистрация</h3>
		                    </div>
		                    <div class="mb-3 text-start">
		                        <label class="form-label" for="email">Емайл</label>
		                        <div class="form-icon-container">
		                        	<input
		                        		v-model="data.email"
	                                    @input="errors.email = null"
	                                    :class="{'is-invalid': errors.email}"
		                        		class="form-control form-icon-input" 
		                        		type="text" 
		                        		placeholder="ivan@mail.com" />
		                        	<span class="fa-regular fa-user text-900 fs--1 form-icon"></span>
		                        	<div class="invalid-feedback">{{errors.email}}</div>
		                        </div>
		                    </div>
		                    <div class="mb-3 text-start">
		                        <label class="form-label" for="password">Пароль</label>
		                        <div class="form-icon-container">
		                        	<input
		                        		v-model="data.password"
                                        @input="errors.password = null"
                                        :class="{'is-invalid': errors.password}"
		                        		class="form-control form-icon-input" 
		                        		type="password" 
		                        		placeholder="Пароль" />
		                        	<span class="fa-regular fa-key text-900 fs--1 form-icon"></span>
		                        	<div class="invalid-feedback">{{errors.password}}</div>
		                        </div>
		                    </div>
		                    <button class="btn btn-primary w-100 mb-3" type="submit">Регистрация</button>

		                    <div style="text-align: center;">
			                    <router-link :to="{name: 'Login'}">Авторизвация</router-link>
			                </div>
		                </form>
	                </div>
	            </div>
	        </div>
	    </div>
	</main>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
	components: { VueRecaptcha },
	data () {
        return {
            data: {
                email: null,
                password: null
            },
            errors: {
                email: false,
                password: false
            }
        }
    },
    methods: {
        async submitForm () {
           	if(!this.data.email) {
                this.errors.email = 'The field is required'
                return
            }
            if(!this.data.password) {
                this.errors.password = 'The field is required'
                return
            }
            
            this.$axios.post('/auth/register', JSON.stringify(this.data))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$refs.recaptcha.reset()
                        this.data.recaptchav3 = null
                        break
                    case "success":

                    	localStorage.setItem('token', response.token)
                            
                        this.$axios.defaults.headers.common.Authorization = 'Bearer ' + response.token

                        this.setUser(response.user)
                        
                        this.$router.push({name: 'StatisticsItems'})
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
            	if(error.response.status == 409) {
            		this.$router.push({name: 'StatisticsItems'})
            		return
            	}

            	let response = error.response.data
            	if('status' in response && response.status == 'error') {
            		if('field' in response) {
            			this.errors[response.field] = response.message
            		}

            		this.$toast.error(response.message)
            	}
            })
        },
    }
}
</script>